import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Logo } from '../../assets/logo';
import { icons, subSectionIcons } from '../../landingPage';
import hamburger from '../../assets/hamburger.svg';
import { sections } from '../../../dustControl/utils/constants';
import styles from './sidebar.module.css';

const Sidebar = (props) => {
  const {
    sidebarActive,
    selectedTab,
    setSelectedTab,
    subSections,
    code,
    setSidebarActive,
  } = props;
  const navigate = useNavigate();

  const subMenu2 = subSections.map((title, index) => ({
    title,
    activeClass: selectedTab === index ? `active` : undefined,
    onClick: () => {
      setSelectedTab(index);
    },
    icon: subSectionIcons[code][index],
  }));

  const onHamburgerClick = () => {
    setSidebarActive(!sidebarActive);
  };

  return (
    <div
      className={`sidebar-container ${
        sidebarActive ? `sidebar-active` : `ps-3 pt-3`
      }`}
      style={{
        background: '#FCFCFD',
        width:
          code === 500
            ? sidebarActive
              ? '240px'
              : '50px'
            : sidebarActive
              ? '220px'
              : '50px',
      }}
    >
      <div className="hamburger-container">
        {sidebarActive && (
          <button
            className="bg-transparent border-0"
            onClick={() => navigate('/landing-page')}
          >
            <Logo textColor="black" />
          </button>
        )}
        <img
          onClick={onHamburgerClick}
          className={`hamburger ${!sidebarActive && `flip-icon`}`}
          src={hamburger}
          alt="hamburger"
        />
      </div>
      {sidebarActive && (
        <>
          <div
            style={{
              borderTop: '1px solid #EFEFEF',
              height: '2px',
              width: '100%',
              margin: '20px 0px 36px 0px',
            }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            className={`${code === 500 || code === 800 ? 'px-4' : ''}`}
          >
            <img src={icons[code]} alt="icon" />
            <div
              className="font-weight-bold p-3"
              style={{ fontSize: '16px', fontWeight: '600', color: '#1A1A1A' }}
            >
              {sections[code]}
            </div>
          </div>

          <div
            className={`sidebar-menu ${code === 500 ? styles.kpiDashboard : ''}`} // condtional class is for  rendering submenu for KPI Dashboard
          >
            {subMenu2.map((item) => (
              <div
                key={item.title}
                className={`sub-menu-text ${item.activeClass} ${
                  item.icon ? 'sub-menu-icon' : ''
                }`}
                onClick={item.onClick}
                style={{ color: '#1A1A1A' }}
              >
                {item.icon && (
                  <div
                    className="iconContainer"
                    style={
                      code === 500
                        ? {
                            height: '50px',
                            width: '50px',
                            padding: '10px',
                            backgroundColor: '#FFFFFF',
                          } // condtional style is for  rendering submenu Icons for KPI Dashboard
                        : {}
                    }
                  >
                    <img src={item.icon} alt="icon" />
                  </div>
                )}
                <div
                  className={`${code === 500 ? 'text-wrap px-0 text-center w-100' : ''}`}
                  style={
                    code === 500
                      ? {
                          wordWrap: 'break-word',
                          overflowWrap: 'break-word',
                        }
                      : {}
                  }
                >
                  {item.title}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Sidebar;
