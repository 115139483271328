import { Asset, GroupedAsset } from './types';

export const parseTimeToDate = (timeStr: string, baseDate: Date): Date => {
  const [time, modifier] = timeStr.split(' ');
  let [hours, minutes] = time.split(':').map(Number);

  if (modifier === 'PM' && hours !== 12) {
    hours += 12;
  }
  if (modifier === 'AM' && hours === 12) {
    hours = 0;
  }

  const date = new Date(baseDate);
  date.setHours(hours, minutes, 0, 0);
  return date;
};

type DateRange = {
  startTimestamp: number;
  endTimestamp: number;
};

export const getInitialDateRange = (): DateRange => {
  const startDate = new Date();
  startDate.setHours(0, 0, 0, 0);
  const startTimestamp = Math.floor(startDate.getTime() / 1000);
  const endTimestamp = Math.floor(Date.now() / 1000);
  return { startTimestamp, endTimestamp };
};

export const groupAssetsByCategory = (assets: Asset[]): GroupedAsset[] => {
  const groupedAssets: { [key: number]: GroupedAsset } = {};

  assets.forEach((item) => {
    const { categoryCode, category, name } = item;

    if (categoryCode != null) {
      if (!groupedAssets[categoryCode]) {
        groupedAssets[categoryCode] = {
          categoryCode: categoryCode,
          category: category,
          assets: [],
        };
      }
      groupedAssets[categoryCode].assets.push(name);
    }
  });

  return Object.values(groupedAssets);
};
