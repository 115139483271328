import React, { createContext, useEffect, useState } from 'react';
import {
  GET_USER_ACCOUNT_DETAILS,
  SAVE_LOGIN_LOGOUT_TIME,
} from '../action/apiPath';

import axios from 'axios';
import jwt_decode from 'jwt-decode';
import * as toast from '../../dustControl/components/toast';
import openSocket from 'socket.io-client';
import { CallWithAuth } from '../../pages/service/apiActions';
import { API } from '../../pages/service/apiHandler';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [status, setStatus] = useState(0);
  const [shiftData, setShiftData] = useState(null);

  const [auth, setAuth] = useState({
    isAuthenticated: false,
    isInitialized: false,
    userId: '',
    roles: [],
    userData: {},
    socketData: {},
    ipAddress: '',
  });

  let logout_time;

  const logout = async () => {
    try {
      const res = await axios.get('https://geolocation-db.com/json/');

      auth?.socketData?.current?.emit('disconnection', {
        id: auth?.userId,
        ip: res?.data?.IPv4,
      });
      auth?.socketData?.current?.on('disconnect', () => {});
      setAuth({
        isAuthenticated: false,
        isInitialized: false,
        userId: '',
        roles: '',
        userData: {},
        socketData: {},
        ipAddress: '',
      });

      logout_time = new Date();
      toast.success('User logout Successfully', {
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }
    const decoded = jwt_decode(accessToken);
    const currentTime = Date.now() / 1000;
    API.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    console.log('currentTime:--', Math.ceil(currentTime));

    return decoded.exp > Math.ceil(currentTime);
  };

  //need to get userData at the time of page reload
  //until loding screen will mount here.

  useEffect(() => {
    async function fetchData() {
      if (
        localStorage.getItem('accessToken') &&
        isValidToken(localStorage.getItem('accessToken'))
      ) {
        try {
          CallWithAuth('GET', GET_USER_ACCOUNT_DETAILS).then(
            async (response) => {
              if (response?.res?.data?.status === 200) {
                const res = await axios.get('https://geolocation-db.com/json/');
                console.log(res.data);

                let socket = {};
                socket.current = openSocket(
                  process.env.REACT_APP_API_ENDPOINT,
                  { path: '' },
                  { query: { data: 'web' } },
                );
                socket.current.on('connect', () => {
                  console.log(
                    'socket.current.connected--',
                    socket.current.connected,
                  );
                  if (socket.current.connected) {
                    socket.current.emit('useradd', {
                      id: response.res.data.data?._id,
                      from: 'web',
                      ip: res?.data?.IPv4,
                    });
                    socket.current.on('useradded', (data) => {});
                  }
                });

                setAuth({
                  ...auth,
                  isAuthenticated: true,
                  isInitialized: false,
                  userId: response.res.data.data?._id,
                  roles: response.res.data.data?.isAdmin ? ['Admin'] : [''],
                  userData: response.res.data?.data,
                  socketData: socket,
                  ipAddress: res?.data?.IPv4,
                });
              } else {
                console.log('=--------------=');
                // logout();
              }
            },
          );
        } catch (error) {
          console.log(error);
        }
      } else {
        if (localStorage.getItem('accessToken')) {
          const headers = {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json',
            // Add any other custom headers as needed
          };
          // try {
          //   const res = await axios.get(
          //     "/dust-control/api/auth/refresh-token",
          //     {
          //       headers,
          //     }
          //   );
          //   if (res.status === 200) {
          //     const userData = res.data;
          //     const [token, departments] = userData.accessToken?.split("||");
          //     axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;

          //     localStorage.setItem("accessToken", token);
          //     localStorage.setItem("refreshToken", userData.refreshToken);
          //   } else {
          //     //  logout();
          //   }
          // } catch (error) {
          //   console.log(error);
          // }
        }
        setAuth({
          ...auth,
          isAuthenticated: false,
          isInitialized: false,
          userId: '',
          roles: '',
          userData: {},
        });
      }
    }
    fetchData();
  }, []);

  // if (!localStorage.getItem("accessToken")) {
  //   return (
  //     <h1
  //       style={{
  //         position: "absolute",
  //         top: "40%",
  //         left: "40%",
  //       }}
  //     >
  //       Loading screen......
  //     </h1>
  //   );
  // }

  const setSocketData = (data) => {
    setAuth({
      ...auth,
      socketData: data,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        logout,
        setSocketData,
        status,
        shiftData,
        setStatus,
        setShiftData,
      }}
    >
      {/* <NetworkDetector /> */}
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
